<template>
  <div class="searchresults">
    <v-form
      ref="form"
      v-model="valid"
      class="searchresults--form"
      @submit.prevent="onClickSave"
    >
      <v-row class="searchresults-header mb-10 mt-4">
        <v-col>
          <r-page-header
            :label="$t('searchResults.title')"
            class="searchresults--pageheader"
          />
        </v-col>
      </v-row>
      <v-row class="searchresults-main-content mt-3">
        <v-col
          sm="6"
          md="6"
          lg="6"
          class="pr-2"
        >
          <r-text-field
            :label="$t('searchResults.fields.name.label')"
            class="searchresults--name"
            :value="name"
            :rules="nameRules"
            @change="onChangeName"
          />
        </v-col>
        <v-col
          sm="6"
          md="6"
          lg="6"
        >
          <span>{{ $t('resources.project') }}</span>
          <r-select
            class="projectselect mt-1"
            :items="projectItems"
            :show-search="false"
            :allow-empty="false"
            @change="onChangeProjectId"
          />
        </v-col>
      </v-row>
      <v-row class="searchresults-secondary-content mt-3">
        <v-col
          sm="6"
          md="6"
          lg="6"
          class="pr-2"
        >
          <div class="automaticallyupdate--container py-3 font-weight-bold">
            <r-switch
              :label="$t('searchResults.fields.automaticallyUpdate.label')"
              :value="automaticallyUpdate"
              :disabled="fetchingSearch"
              @change="onChangeAutomaticallyUpdate"
            />
          </div>
          <div
            v-if="automaticallyUpdate"
            class="automaticallyaddurls--container py-6 px-5"
          >
            <r-switch
              :label="$t('searchResults.fields.automaticallyCreateUrls.label')"
              :value="automaticallyCreateUrls"
              :disabled="fetchingSearch"
              @change="onChangeAutomaticallyCreateUrls"
            />
          </div>
        </v-col>
        <v-col
          sm="6"
          md="6"
          lg="6"
        >
          <span class="font-weight-bold">
            {{ $t('searchResults.fields.lastListingCheck.label') }}
          </span>
          <span>
            {{ lastUpdateDate }}
          </span>
        </v-col>
      </v-row>
      <v-row class="searchresults-actions mt-2">
        <v-col
          sm="12"
          md="12"
          lg="12"
        >
          <r-error-message
            class="searchresults--error"
            :errors="[
              fetchSearchError,
              fetchUpdateSearchError
            ]"
          />
        </v-col>
      </v-row>
    </v-form>

    <r-modal
      v-if="showAddUrlsSuccessModal"
      class="addsearchresult--modal"
      title="URLs added and queued for data collection"
    >
      <template v-slot:customActions>
        <r-button
          :outlined="true"
          class="close-btn"
          :label="$t('global.actions.backToData')"
          @click="backToData"
        />
      </template>
    </r-modal>

    <v-row class="searchresults-newresults mt-4">
      <v-col
        sm="12"
        md="12"
        lg="12"
      >
        <r-error-message
          class="searchnewresult--error"
          :errors="[fetchNewResultsError]"
        />
      </v-col>
      <v-col
        sm="12"
        md="12"
        lg="12"
      >
        <r-page-header
          :label="$t('searchResults.newUrls')"
          :type="PAGE_HEADER_TYPES.SUB_TITLE"
        >
          <template v-slot:end>
            <r-button
              class="mr-2 searchresults--cancel"
              label="Cancel"
              :outlined="true"
              @click="onClickCancel"
            />
            <r-button
              class="searchresults--save"
              label="Save"
              :disable="fetchingSearch || fetchingUpdateSearch"
              :loading="fetchingUpdateSearch"
              @click="onClickSave"
            />
          </template>
        </r-page-header>
        <div class="mt-4 text--secondary">
          <v-row>
            <v-col class="d-flex justify-end">
              <div>
                <r-button
                  :label="$t('searchResults.addData')"
                  class="searchresults--add-data"
                  :disabled="!hasSelectedItems || isUserOverQuota"
                  :loading="fetchingAddSearchUrls"
                  @click="onClickAddData"
                />
              </div>
              <div>
                <v-divider
                  vertical
                  class="mx-2"
                  style="height:25"
                />
              </div>
              <div>
                <r-search-input
                  :value="searchTerm"
                  style="width:300px"
                  @change="onChangeSearch"
                />
              </div>
              <!-- <div>
                <r-anchored-popup
                  v-slot="{ onClose }"
                  :label="$t('searchResults.filters')"
                  icon="filter_list"
                  class="searchresults--filter-button"
                >
                  <new-result-filters
                    :applied-filters="newResultFilters"
                    @apply="onClickApplyNewResultsFilters"
                    @close="onClose"
                  />
                </r-anchored-popup>
              </div> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col
              sm="12"
              md="12"
              lg="12"
              class="py-0"
            >
              <r-error-message
                class="searchaddnewresult--error"
                :errors="[
                  fetchAddSearchUrlsError,
                  userOverQuotaMessage
                ]"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <new-result-filter-chips
                :filters="newResultFilters"
                :loading="fetchingNewResults"
                @apply="onClickApplyNewResultsFilters"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col
        sm="12"
        md="12"
        lg="12"
        class="pt-0"
      >
        <r-table
          :class="{ 'no-results': !newResults.length }"
          :columns="tableColumns"
          :rows="newResults"
          :page="newResultPage"
          :page-size="newResultPageSize"
          :total-rows="totalNewResults"
          :loading="fetchingNewResults"
          @change:page="onChangeNewResultPage"
          @change:page-size="onChangeNewResultsPageSize"
          @change:page-size-all="onChangeNewResultsPageSizeAll"
          @change:batch-select="onChangeBatchNewResultSelection"
          @change:batch-select-all="onSelectAllNewResults"
          @sort:asc="({ value }) => onChangeNewResultsSort({ sort: value, sortDir: 'asc' })"
          @sort:desc="({ value }) => onChangeNewResultsSort({ sort: value, sortDir: 'desc' })"
        >
          <template v-slot:item.name="{ item }">
            <div
              class="text-truncate"
              :title="item.name"
            >
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.url="{ item }">
            <a
              :href="item.url"
              :title="item.url"
              target="_blank"
              v-html="abbrevUrl(item.url)"
            />
          </template>
          <template v-slot:item.numReviews="{ item }">
            <span>{{ formatNumReview(item.numReviews) }}</span>
          </template>
          <template v-slot:item.linkName="{ item }">
            <div
              class="text-truncate"
              :title="item.linkName"
            >
              {{ item.linkName }}
            </div>
          </template>
        </r-table>
      </v-col>
    </v-row>
    <v-row
      v-if="totalAddedResults"
      class="searchresults-addedresults mt-4"
    >
      <v-col
        sm="12"
        md="12"
        lg="12"
      >
        <r-error-message
          :errors="[fetchAddedResultsError]"
        />
      </v-col>
      <v-col
        sm="12"
        md="12"
        lg="12"
      >
        <r-page-header
          :label="$t('searchResults.addedUrls')"
          :type="PAGE_HEADER_TYPES.SUB_TITLE"
        />
      </v-col>
      <v-col
        sm="12"
        md="12"
        lg="12"
      >
        <r-table
          :columns="tableColumns"
          :rows="addedResults"
          :page="addedResultPage"
          :page-size="addedResultPageSize"
          :total-rows="totalAddedResults"
          :loading="fetchingAddedResults"
          :is-selectable="false"
          @change:page="onChangeAddedResultPage"
          @change:page-size="onChangeAddedResultsPageSize"
          @change:page-size-all="onChangeAddedResultsPageSizeAll"
          @sort:asc="({ value }) => onChangeAddedResultsSort({ sort: value, sortDir: 'asc' })"
          @sort:desc="({ value }) => onChangeAddedResultsSort({ sort: value, sortDir: 'desc' })"
        >
          <template v-slot:item.name="{ item }">
            <div
              class="text-truncate"
              :title="item.name"
            >
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.url="{ item }">
            <a
              :href="item.url"
              :title="item.url"
              target="_blank"
              v-html="abbrevUrl(item.url)"
            />
          </template>
          <template v-slot:item.numReviews="{ item }">
            <span>{{ formatNumReview(item.numReviews) }}</span>
          </template>
        </r-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RModal from '@/components/library/organisms/RModal'
import RTable from '@/components/library/organisms/RTable'
import RSelect from '@/components/library/molecules/RSelect'
import RSwitch from '@/components/library/atoms/RSwitch'
import RButton from '@/components/library/atoms/RButton'
import RTextField from '@/components/library/molecules/RTextField'
import RPageHeader from '@/components/library/molecules/RPageHeader'
import RSearchInput from '@/components/library/molecules/RSearchInput'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
// import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup'
// import NewResultFilters from '@/components/app/search/NewResultFilters'
import NewResultFilterChips from '@/components/app/search/NewResultFilterChips'

import { mapActions, mapGetters, mapState } from 'vuex'
import { ALL_DATA_PROJECT, PAGE_HEADER_TYPES } from '@/utils/constants'

import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'
import abbrevUrl from '@/utils/abbrevUrl'

export default {
  name: 'SearchResults',
  components: {
    RModal,
    RTable,
    RSwitch,
    RButton,
    RSelect,
    RTextField,
    RPageHeader,
    RSearchInput,
    RErrorMessage,
    // RAnchoredPopup,
    // NewResultFilters,
    NewResultFilterChips,
  },
  data() {
    return {
      valid: false,
      name: '',
      projectId: null,
      automaticallyUpdate: false,
      automaticallyCreateUrls: false,
      selectedResults: [],
      allResultsSelected: false,
      searchTerm: '',
      showAddUrlsSuccessModal: false,
      tableColumns: [
        {
          key: 'name',
          label: 'URLs',
          hideable: true,
          sortable: false,
          width: 300,
        },
        {
          key: 'url',
          label: 'Link',
          hideable: true,
          sortable: false,
          width: 250,
        },
        /**
         * Temporarily commenting-out/removing Reviews(Ratings) column from the UI.
         * Reference ticket - https://brandwatch.atlassian.net/browse/REV-1181
         */
        // {
        //   key: 'numReviews',
        //   label: 'Reviews',
        //   hideable: true,
        //   sortable: true,
        //   width: 100,
        // },
        {
          key: 'linkName',
          label: 'Link Name',
          hideable: true,
          sortable: false,
          width: 100,
        },
      ],
      nameRules: [
        (value) => !!value || this.$i18n.t('global.validations.messages.required'),
      ],
      newResultFilters: {},
      newResultPage: 1,
      newResultPageSize: 50,
      newResultsSort: 'created',
      newResultsSortDir: 'desc',
      addedResultPage: 1,
      addedResultPageSize: 50,
      addedResultsSort: 'addedDate',
      addedResultsSortDir: 'desc',
      PAGE_HEADER_TYPES,
    }
  },
  computed: {
    ...mapGetters('users', [
      'isOverQuota',
    ]),
    ...mapState('projects', [
      'projects',
    ]),
    ...mapState('search', [
      'search',
      'fetchingSearch',
      'fetchSearchError',
      'fetchingUpdateSearch',
      'fetchUpdateSearchError',
      'fetchingAddSearchUrls',
      'fetchAddSearchUrlsError',
      'newResults',
      'totalNewResults',
      'fetchingNewResults',
      'fetchNewResultsError',
      'addedResults',
      'totalAddedResults',
      'fetchingAddedResults',
      'fetchAddedResultsError',
    ]),
    ...mapState('users', [
      'userQuota',
      'fetchingUserQuota',
      'fetchUserQuotaError',
    ]),
    projectItems() {
      const { projectId } = this.search
      const allDataProject = {
        label: ALL_DATA_PROJECT.name,
        value: ALL_DATA_PROJECT.id,
        selected: !projectId,
      }

      const items = this.projects.map(({ _id, name }) => ({
        label: name,
        value: _id,
        selected: projectId === _id,
      }))

      return [allDataProject].concat(items)
    },
    hasSelectedItems() {
      return this.selectedResults.length || this.allResultsSelected
    },
    lastUpdateDate() {
      const { lastRun } = this.search

      if (!lastRun) {
        return this.$t('searchResults.fields.lastListingCheck.never')
      }

      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(lastRun),
        format: 'DD/MM/YYYY hh:mm',
      })
    },
    isUserOverQuota() {
      if (this.fetchingUserQuota || this.fetchUserQuotaError) {
        return false
      }

      return this.isOverQuota
    },
    userOverQuotaMessage() {
      if (!this.isOverQuota) {
        return ''
      }

      const { urlsQuota } = this.userQuota
      const { maxUrls } = urlsQuota
      return this.$t('searchResults.isUserOverQuotaMessage', { maxUrls })
    },
  },
  watch: {
    search(searchState) {
      this.loadSearch(searchState)
    },
  },
  mounted() {
    const { searchId } = this.$route.params
    this.fetchSearch({ searchId })
    this.fetchProjects()
    this.fetchUserQuota()
    this.fetchNewResults()
    this.fetchAddedResults()
  },
  methods: {
    ...mapActions('search', [
      'fetchSearch',
      'updateSearch',
      'addSearchResultsToUrls',
      'fetchSearchNewResults',
      'fetchSearchAddedResults',
    ]),
    ...mapActions('projects', [
      'fetchProjects',
    ]),
    ...mapActions('users', [
      'fetchUserQuota',
    ]),
    fetchNewResults() {
      const { searchId } = this.$route.params
      const {
        newResultFilters,
        newResultPage,
        newResultPageSize,
        searchTerm,
        newResultsSort,
        newResultsSortDir,
      } = this

      this.fetchSearchNewResults({
        searchId,
        page: newResultPage,
        pageSize: newResultPageSize,
        search: searchTerm,
        sort: newResultsSort,
        sortDir: newResultsSortDir,
        filters: newResultFilters,
      })
    },
    fetchAddedResults() {
      const { searchId } = this.$route.params
      const {
        addedResultPage,
        addedResultPageSize,
        searchTerm,
        addedResultsSort,
        addedResultsSortDir,
      } = this

      this.fetchSearchAddedResults({
        searchId,
        page: addedResultPage,
        pageSize: addedResultPageSize,
        search: searchTerm,
        sort: addedResultsSort,
        sortDir: addedResultsSortDir,
      })
    },
    getResultsOrFilters() {
      const hasProjectId = this.projectId !== ALL_DATA_PROJECT.id
      const basePayload = {
        ...(hasProjectId && { projectId: this.projectId }),
      }

      if (this.allResultsSelected) {
        return {
          ...basePayload,
          filters: {
            search: this.searchTerm,
            ...this.newResultFilters,
          },
        }
      }

      return {
        ...basePayload,
        results: this.selectedResults.map(({ _id, url }) => ({ _id, url })),
      }
    },
    onClickApplyNewResultsFilters({ value: filters }) {
      this.newResultFilters = filters
      this.fetchNewResults()
    },
    async onClickSave() {
      if (!this.$refs.form.validate()) {
        return
      }

      const updatePayload = {
        searchId: this.search._id,
        name: this.name,
        projectId: this.projectId === ALL_DATA_PROJECT.id ? undefined : this.projectId,
        automaticallyUpdate: this.automaticallyUpdate,
        automaticallyCreateUrls: this.automaticallyCreateUrls,
      }

      await this.updateSearch({ ...updatePayload })
    },
    onClickCancel(e) {
      e.preventDefault()

      this.$router.push('/data')
    },
    async onClickAddData(e) {
      e.preventDefault()

      await this.onClickSave()

      const { _id: searchId } = this.search

      await this.addSearchResultsToUrls({
        searchId,
        ...this.getResultsOrFilters(),
      })

      if (!this.fetchAddSearchUrlsError) {
        this.showAddUrlsSuccessModal = true
        this.fetchNewResults()
        this.fetchAddedResults()
      }
    },
    loadSearch(search) {
      if (!search) {
        return
      }

      const {
        name,
        projectId,
        automaticallyUpdate,
        automaticallyCreateUrls,
      } = this.search

      this.name = name
      this.projectId = projectId || ALL_DATA_PROJECT.id
      this.automaticallyUpdate = automaticallyUpdate
      this.automaticallyCreateUrls = automaticallyCreateUrls
    },
    onChangeName({ value: newName }) {
      this.name = newName
    },
    onChangeProjectId({ value: newValue }) {
      this.projectId = newValue
    },
    onChangeSelectedProject({ value: selectedProjectId }) {
      this.projectId = selectedProjectId || ALL_DATA_PROJECT.id
    },
    onChangeAutomaticallyUpdate({ value: newValue }) {
      this.automaticallyUpdate = newValue
      if (!newValue) {
        this.automaticallyCreateUrls = false
      }
    },
    onChangeAutomaticallyCreateUrls({ value: newValue }) {
      this.automaticallyCreateUrls = newValue
    },
    onChangeSearch({ value: searchTerm }) {
      this.newResultPage = 1
      this.searchTerm = searchTerm
      this.fetchNewResults()
    },
    onSelectAllNewResults() {
      this.allResultsSelected = true
    },
    onChangeNewResultPage({ value: page }) {
      this.newResultPage = page
      this.fetchNewResults()
    },
    onChangeNewResultsPageSize({ value: pageSize }) {
      this.newResultPageSize = pageSize
      this.fetchNewResults()
    },
    onChangeNewResultsPageSizeAll() {
      this.newResultPageSize = this.totalNewResults
      this.fetchNewResults()
    },
    onChangeNewResultsSort({ sort, sortDir }) {
      this.newResultsSort = sort
      this.newResultsSortDir = sortDir

      this.fetchNewResults()
    },
    onChangeBatchNewResultSelection({ value: selectedItems }) {
      this.allResultsSelected = false
      this.selectedResults = selectedItems
    },
    onChangeAddedResultPage({ value: page }) {
      this.addedResultPage = page
      this.fetchAddedResults()
    },
    onChangeAddedResultsPageSize({ value: pageSize }) {
      this.addedResultPageSize = pageSize
      this.fetchAddedResults()
    },
    onChangeAddedResultsPageSizeAll() {
      this.addedResultPageSize = this.totalAddedResults
      this.fetchAddedResults()
    },
    onChangeAddedResultsSort({ sort, sortDir }) {
      this.addedResultsSort = sort
      this.addedResultsSortDir = sortDir

      this.fetchAddedResults()
    },
    abbrevUrl(url) {
      return abbrevUrl(url)
    },
    formatNumReview(numReviews) {
      if (!numReviews) {
        return this.$t('searchResults.numReviewsNotAvailable')
      }

      return numReviews
    },
    backToData() {
      this.$router.push('/data')
    },
  },
}
</script>

<style scoped>
.automaticallyaddurls--container {
  background-color: var(--soft-grey-color);
}
/deep/ .searchresults--filter-button .v-btn {
  padding: 7px 16px !important;
}

/deep/ .anchor-card {
  right: -10px !important;
  top: -7px !important;
}

/deep/ .rtable.no-results {
  min-height: 10rem;
  height: 10rem !important;
}
</style>
