<template>
  <div class="newresultsfilterchips d-flex">
    <r-chip
      v-for="filter in renderedFilters"
      :key="`newresultsfilterchips-${filter.key}`"
      :label="filter.label"
      :disabled="loading"
      :removable="true"
      class="newresultsfilterchips--chip mb-4 mr-2"
      @click="onClickRemove([filter.key])"
    />
  </div>
</template>

<script>
import RChip from '@/components/library/atoms/RChip'

const FILTER_TO_LABEL = {
  minReviews: (value) => `Min Reviews: ${value}`,
  maxReviews: (value) => `Max Reviews: ${value}`,
}

const filterKeyMapper = (appliedFilters) => (key) => {
  const value = appliedFilters[key]
  if (!value) {
    return null
  }

  return {
    key,
    label: FILTER_TO_LABEL[key](value),
  }
}

export default {
  name: 'NewResultFilterChips',
  components: {
    RChip,
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    renderedFilters() {
      const filterKeys = Object.keys(FILTER_TO_LABEL)

      return Object.keys(this.filters)
        .filter((key) => filterKeys.includes(key))
        .map(filterKeyMapper(this.filters))
        .filter((label) => !!label)
    },
  },
  methods: {
    onClickRemove(keys) {
      const filters = { ...this.filters }
      keys.forEach((key) => {
        delete filters[key]
      })

      this.$emit('apply', { value: filters })
    },
  },
}
</script>
